import * as powerbi from 'powerbi-client';

import API from '@/modules/reports/_api';

import { getFunctionAndFileName } from '@/Core.Patterns/Factory';

import cons from '../constants';

let report = null;

const minutesBeforeExpiration = 5;

const intervalTime = 15000;

// Embed a Power BI report in the given HTML element with the given configurations
// Read more about how to embed a Power BI report in your application here: https://go.microsoft.com/fwlink/?linkid=2153590
const getEmbed = async (container, configuration) => {
  /*-----------------------------------------------------------------------------------+
    |    Don't change these values here: access token, embed URL and report ID.          | 
    |    To make changes to these values:                                                | 
    |    1. Save any other code changes to a text editor, as these will be lost.         |
    |    2. Select 'Start over' from the ribbon.                                         |
    |    3. Select a report or use an embed token.                                       |
    +-----------------------------------------------------------------------------------*/
  // Embed the report and display it within the div container.
  const pbi = new powerbi.service.Service(
    powerbi.factories.hpmFactory,
    powerbi.factories.wpmpFactory,
    powerbi.factories.routerFactory
  );

  report = pbi.embed(container, configuration);

  // report.off removes all event handlers for a specific event
  report.off('loaded');

  // report.on will add an event handler
  report.on('loaded', () => {
    report.off('loaded');
  });

  //report.off removes all event handlers for a specific event
  // clears the event handlers before starting the event listener
  report.off('dataSelected');

  // report.on start the event listener
  report.on('dataSelected', async (event) => {
    // store the events
    const data = event.detail;
    // set the variables for the slicers that need to be adjusted
    const slicerThatChanged = 'Site Slicer';
    const slicersToReset = ['End Use Slicer'];
    // filter events for only slicers
    if (data.visual.type !== 'slicer') return;

    try {
      // persist new site through out tab changes
      if (
        data.visual.title === 'Site Slicer' &&
        data.dataPoints[0].identity[0].target.column == 'SiteName'
      ) {
        const newSite = data.dataPoints[0].identity[0].equals;
        report.config.reportParameters.SiteName = newSite;
      }
      // run function to reset slicers
      await resetSlicers(
        data.visual.name,
        slicerThatChanged,
        slicersToReset,
        report
      );
    } catch (errors) {
      const { functionName, fileName } = getFunctionAndFileName(errors);
      console.error(`Error in ${functionName} in ${fileName} =>`, errors);
    }
  });

  const { accessToken, reportName } = report.config;

  window.refreshTokenInterval = setInterval(
    () => checkTokenAndUpdate(reportName, accessToken),
    intervalTime
  );

  return report;
};

const checkTokenAndUpdate = (reportName, accessToken) => {
  // Get the current time
  const currentTime = Date.now();
  const expiration = extractAndParseJWT(accessToken);

  // Time until token expiration in milliseconds
  const timeUntilExpiration = expiration - currentTime;
  const timeToUpdate = minutesBeforeExpiration * 60 * 1000;

  // Update the token if it is about to expired
  if (timeUntilExpiration <= timeToUpdate) {
    updateToken(reportName);
  }
};

const updateToken = async (reportName) => {
  // Generate a new embed token or refresh the user Azure AD access token
  try {
    const newAccessToken = await getNewUserAccessToken(reportName);

    // Set the new access token
    await report.setAccessToken(newAccessToken.accessToken);
  } catch (errors) {
    console.error('Error updating the access token:', errors);
  }
};

const getNewUserAccessToken = async (reportName) => {
  // Get the new access token from the server
  return await API.getPowerBiEmbedConfiguration(reportName);
};

const extractAndParseJWT = (tokenString) => {
  // Step 1: Extract the JWT token from the provided string
  const token = tokenString;

  if (!token) {
    console.error('No token found in the string.');
    return;
  }

  // Step 2: Base64 decode the payload of the token
  const base64Payload = token.split('.')[1];
  if (!base64Payload) {
    console.error('Invalid token: payload segment missing.');
    return;
  }

  const payload = atob(base64Payload.replace(/-/g, '+').replace(/_/g, '/'));

  try {
    // Step 3: Parse the payload JSON and extract the expiration date
    const jwtPayload = JSON.parse(payload);
    const expirationDate = new Date(jwtPayload.exp * 1000);
    return expirationDate;
  } catch (e) {
    console.error('Error parsing JWT payload:', e);
  }
};

// Refresh the report
export const refreshDefaultData = async () => {
  try {
    await report.refresh();
    return Promise.resolve();
  } catch (errors) {
    return Promise.reject(errors);
  }
};

const resetSlicers = async (
  changedSlicerName,
  slicerToListenToName,
  slicersToResetNames,
  report
) => {
  // get list of all slicers on active page
  const pageSlicers = await getSlicersForActivePage(report);
  // get the slicer visual and compare with the visual that fired the data selected event
  const changingSlicer = pageSlicers.filter(
    (s) => s.title === slicerToListenToName
  )[0];
  if (changingSlicer.name !== changedSlicerName) return;

  // for each slicer to be reset reset them.
  const slicersToReset = pageSlicers.filter((s) =>
    slicersToResetNames.includes(s.title)
  );

  slicersToReset.map(async (s) => {
    await s.setSlicerState({
      filters: [
        {
          $schema: cons.BASIC_FILTER,
          target: {
            table: 'vwEndUseNames',
            column: 'EndUseName',
          },
          filterType: 1,
          operator: 'In',
          values: [1],
        },
        // {
        //   $schema: cons.TOPN_FILTER,
        //   target: {
        //     table: 'vwEndUseNames',
        //     column: 'EndUseName',
        //   },
        //   filterType: 5,
        //   operator: 'Top',
        //   itemCount: 1,
        //   orderBy: {
        //     table: 'vwEndUseNames',
        //     measure: 'EndUseName',
        //   },
        // },
      ],
    });
  });
};

// Select Run and select an element of a visualization.
// For example, a bar in a bar chart. You should see an entry in the Log window.
const getSlicersForActivePage = async (report) => {
  // Retrieve the page collection and get the visuals for the active page.
  try {
    const pages = await report.getPages();
    // Retrieve the active page.
    const pageWithSlicer = pages.filter((page) => {
      return page.isActive;
    })[0];
    const visuals = await pageWithSlicer.getVisuals();
    // Retrieve the target visual.
    const slicers = visuals.filter((visual) => {
      return visual.type === 'slicer';
    });
    // Get the slicer state
    return slicers;
  } catch (errors) {
    const { functionName, fileName } = getFunctionAndFileName(errors);
    console.error(`Error in ${functionName} in ${fileName} =>`, errors);
    return [];
  }
};

export default {
  getEmbed,
};
