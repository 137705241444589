import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#2E3C54',
        accentOrange: '#FF6E52',
        warning: '#FF6E52',
        charcoal: '#45484D',
        eerieBlack: '#18191A',
        intechBlue: '#21AAD4',
        heatColor: '#B05FA1',
        background: '#F8FCFF', //we're locking this in so the powerbi report backgrounds melt into ours
        aliceBlue: '#F8FCFF',
        greenGraph: '#62BE37',
        sonicSilver: '#737880',
        purpleGraph: '#CC00ED',
        disabled: '#E0E0E0',

        daikin: '#00CDFF',
      },
    },
    options: {
      customProperties: true,
    },
  },
});
