//Power Details
//Power Heatmap
//Site Slicer - vwSite - SiteName
//Electricity Date Slicer - vwReportingDateTime - Date
//Gas Details
//Gas Heatmap
//Site Slicer - vwSite - Sitename
//Gas Date Slicer - vwReportingDateTime - Date
//Billing

import cons from '../constants';

const setElectricitySlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setElectricityDateSlicerVisual(reportParameters, visuals);
};

const setGasSlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setGasDateSlicerVisual(reportParameters, visuals);
};

async function setSiteSlicerVisual(reportParameters, visuals) {
  const siteSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };
  let siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
  }
}

async function setElectricityDateSlicerVisual(reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.ElectricityStartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.ElectricityEndDate,
      },
    ],
  };

  let dateSlicerVisual = visuals.filter(function (visual) {
    return (
      visual.type === 'slicer' && visual.title === 'Electricity Date Slicer'
    );
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
    console.log('Electricity Date Slicer slicer was set.');
  }
}

async function setGasDateSlicerVisual(reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwReportingDateTime',
      column: 'Date',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.GasStartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.GasEndDate,
      },
    ],
  };

  let dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Gas Date Slicer';
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
    console.log('Gas Date Slicer slicer was set.');
  }
}

export default {
  setElectricitySlicers,
  setGasSlicers,
};
