//Dashboard
//Site Slicer - vwSite - SiteName
//Monitor Name Slicer - vwSensorNames - MonitorFriendlyName
//Overtime
//Date Slicer - vwReportingDateTime - Date

import cons from '../constants';

const setSlicers = async function (reportParameters, visuals) {
  await setSiteSlicerVisual(reportParameters, visuals);
  await setMonitorNameSlicerVisual(reportParameters, visuals);
};

const setOvertimeSlicer = async function (reportParameters, visuals) {
  await setDateSlicerVisual(reportParameters, visuals);
};

async function setSiteSlicerVisual(reportParameters, visuals) {
  const siteSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwSite',
      column: 'SiteName',
    },
    operator: 'In',
    values: [reportParameters.SiteName],
  };
  let siteSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Site Slicer';
  })[0];

  if (siteSlicerVisual) {
    // Set the slicer state which contains the slicer filters.
    await siteSlicerVisual.setSlicerState({
      filters: [siteSlicerFilter],
    });
    console.log('Site slicer was set.');
  }
}

async function setDateSlicerVisual(reportParameters, visuals) {
  const dateSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwReportingDateTime',
      column: 'DateTime',
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: reportParameters.StartDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: reportParameters.EndDate,
      },
    ],
  };
  let dateSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Date Slicer';
  })[0];

  if (dateSlicerVisual) {
    await dateSlicerVisual.setSlicerState({
      filters: [dateSlicerFilter],
    });
    console.log('Date slicer was set.');
  } else {
    console.log('Date slicer not set.');
  }
}

async function setMonitorNameSlicerVisual(reportParameters, visuals) {
  const monitorNameSlicerFilter = {
    $schema: cons.ADVANCED_FILTER,
    target: {
      table: 'vwSensorNames',
      column: 'MonitorFriendlyName',
    },
    operator: 'In',
    values: [reportParameters.SensorName],
  };

  let monitorSlicerVisual = visuals.filter(function (visual) {
    return visual.type === 'slicer' && visual.title === 'Monitor Name Slicer';
  })[0];
  if (monitorSlicerVisual) {
    await monitorSlicerVisual.setSlicerState({
      filters: [monitorNameSlicerFilter],
    });
    console.log('Monitor Name slicer was set.');
  } else {
    console.log('Monitor Name slicer not set.');
  }
}

export default {
  setSlicers,
  setOvertimeSlicer,
};
