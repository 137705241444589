<template>
  <section style="height: 100%">
    <div v-if="hasSubmit">
      <v-btn @click="getReport">Get Report</v-btn>
    </div>
    <div v-show="!isReportLoaded" class="center-progress">
      <v-progress-circular :size="50" color="primary" indeterminate />
      <p class="mt-5 primary--text">Loading Report...</p>
    </div>
    <div
      v-show="isReportLoaded"
      style="height: 100%"
      class="mt-4"
      id="reportContainer"
      ref="reportContainer"
    />
  </section>
</template>
<script>
import { refreshDefaultData } from '@/modules/reports/_factories/embedReports/defaultEmbed.js';

import api from '../_api';
import powerBiReportfactory from '../_factories/powerBiReportFactory.js';

import { mapGetters } from 'vuex';

export default {
  name: 'PowerBIReport',
  props: {
    reportName: { type: String, required: true },
    siteId: { type: String, required: false },
    hasSubmit: { type: Boolean, default: false },
    maxHeight: { type: Boolean, default: true },
    height: { type: Number, default: -1 },
    controlFriendlyName: { type: String, default: '' },
  },

  data() {
    return {
      embedConfiguration: null,
      refreshRate: 30000,
      isRealTimeRefreshing: false,
      timeoutId: null,
      isDashboard: false,
      intervalId: null,
      isReportLoaded: false,
    };
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
    clearInterval(window.refreshTokenInterval);
    this.$route.meta.isRealTimeReport = false;
  },

  watch: {
    reportName: function (newVal) {
      if (!this.hasSubmit && newVal && newVal !== '') {
        this.getReport();
      }
    },
    reportInfo: {
      handler(value) {
        if (value && /real\s*-?\s*time/gi.test(value.reportType)) {
          this.$route.meta.isRealTimeReport = true;
          if (!this.isDashboard) {
            // this is checking if is dashboard, because dashboard types won't work with the refresh function from the powerbi library
            if (this.intervalId) {
              clearInterval(this.intervalId);
            }
            this.intervalId = setInterval(() => {
              try {
                refreshDefaultData();
              } catch (error) {
                console.error('Error refreshing data: ', error);
              }
            }, this.refreshRate);
          }
        } else {
          this.$route.meta.isRealTimeReport = false;
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      reportInfo: 'ViewSupportStore/ReportsStore/getReportInfo',
    }),
  },

  created() {
    this.getReport();
  },

  methods: {
    getReport: async function () {
      const embedConfiguration = await api.getPowerBiEmbedConfiguration(
        this.reportName,
        this.siteId
      );

      this.embedConfiguration = embedConfiguration;

      if (embedConfiguration.type === 'dashboard') {
        this.isDashboard = true;
      } else {
        this.isDashboard = false;
      }

      if (this.controlFriendlyName) {
        embedConfiguration.reportParameters.SensorName =
          this.controlFriendlyName;
      }

      const container = document.getElementById('reportContainer');
      container.addEventListener('loaded', () => {
        this.isReportLoaded = true;
      });

      powerBiReportfactory.getPowerBiReportStrategy(
        this.reportName,
        container,
        embedConfiguration
      );
    },
  },
};
</script>
<style>
section {
  height: 100%;
}

#reportContainer {
  width: 100%;
}

#reportContainer iframe {
  border: none;
}

.center-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
